import React from "react";
import { FaFacebookF, FaInstagram, FaHome } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import QRCodeGenerator from "./qr-code-generator";

const Footer: React.FC = () => {
    return (
        <footer className="bg-gray-800 text-gray-300 py-12">
            <div className="container mx-auto px-6 sm:px-10 lg:px-16 xl:px-20">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* About Section */}
                    <div>
                        <h3 className="text-lg font-semibold text-white mb-4">AHI</h3>
                        <p className="text-sm">
                            We specialize in transforming homes with water filtration systems, solar panels,
                            and energy-efficient upgrades to make your living space comfortable and eco-friendly.
                        </p>
                    </div>

                    {/* Navigation Links */}
                    <div>
                        <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
                        <ul className="text-sm space-y-2">
                            <li>
                                <a href="#services" className="hover:text-green-400 transition">
                                    Our Services
                                </a>
                            </li>
                            <li>
                                <a href="#about" className="hover:text-green-400 transition">
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a href="#testimonials" className="hover:text-green-400 transition">
                                    Testimonials
                                </a>
                            </li>
                            <li>
                                <a href="#contact" className="hover:text-green-400 transition">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-lg inline-block">

                        <QRCodeGenerator />
                    </div>
                    {/* Contact Info */}
                    <div>
                        <h3 className="text-lg font-semibold text-white mb-4">Get in Touch</h3>
                        <ul className="text-sm space-y-4">
                            <li>
                                <a
                                    href="tel:9095291563"
                                    className="hover:text-green-400 transition flex items-center"
                                >
                                    <FaPhoneVolume className="inline-block text-xl mr-1" />
                                    <span>(909) 529-1563</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="mailto:betterhomes@aquahomeimprovements.com"
                                    target="_self"
                                    rel="noopener noreferrer"
                                    className="hover:text-green-400 transition flex items-center"
                                >
                                    <TfiEmail className="inline-block text-xl mr-1" />
                                    <span>betterhomes@aquahomeimprovements.com</span>
                                </a>
                            </li>

                            <li className="flex items-center"><FaHome className="inline-block text-xl mr-1" />
                                <span>Hesperia California</span></li>
                        </ul>
                        <div className="flex mt-4 space-x-4">
                            <a
                                href="lkj"
                                className="text-gray-400 hover:text-white transition text-lg"
                                aria-label="Facebook"
                            >
                                <FaFacebookF />
                            </a>
                            <a
                                href="lkj"
                                className="text-gray-400 hover:text-white transition text-lg"
                                aria-label="Instagram"
                            >
                                <FaInstagram />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="mt-8 text-center border-t border-gray-700 pt-6">
                    <p className="text-sm">&copy; {new Date().getFullYear()} Aqua Home Improvements. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
